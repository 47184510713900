import React from "react";
import * as Icon from "react-feather";

const adminMenuConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={16} />,
    navLink: "/dashboard",
  },
  {
    id: "customer",
    title: "List Customer",
    type: "item",
    icon: <Icon.User size={16} />,
    navLink: "/list-customer",
  },
  {
    id: "user-admin",
    title: "User Admin",
    type: "item",
    icon: <Icon.Users size={16} />,
    navLink: "/user-admin",
  },
  {
    id: "assets-package",
    title: "Assets Package",
    type: "item",
    icon: <Icon.Package size={16} />,
    navLink: "/assets-package",
  },
  {
    id: "item-menu",
    title: "Item Menu",
    type: "item",
    icon: <Icon.BarChart size={16} />,
    navLink: "/item-menu",
  },
  {
    id: "item-db",
    title: "Database",
    type: "collapse",
    icon: <Icon.Database size={16} />,
    children: [
      {
        id: "item-migrqation",
        title: "DB Migration",
        type: "item",
        icon: <Icon.Airplay size={16} />,
        navLink: "/db-migration",
      },
      {
        id: "item-migrqation",
        title: "DB Backup",
        type: "item",
        icon: <Icon.Archive size={16} />,
        navLink: "/db-backup",
      },
      {
        id: "item-migrqation",
        title: "DB Restore",
        type: "item",
        icon: <Icon.Archive size={16} />,
        navLink: "/db-restore",
      },
    ],
  },
  ,
  {
    id: "others",
    title: "Master Data",
    type: "collapse",
    icon: <Icon.Package size={16} />,
    children: [
      {
        id: "secondLevel",
        title: "Application Custer",
        icon: <Icon.Circle size={10} />,
        type: "item",
        navLink: "/application-cluster",
        // navlink: "",
        // permissions: ["admin", "editor"]
      },
      {
        id: "tenantmaster",
        title: "Tenant Master",
        icon: <Icon.Circle size={10} />,
        type: "item",
        navLink: "/tenant-master",
        // navlink: "",
        // permissions: ["admin", "editor"]
      },
      {
        id: "table-column-exception",
        title: "Table Column Exception",
        icon: <Icon.Circle size={10} />,
        type: "item",
        navLink: "/table-column-exception",
        // navlink: "",
        // permissions: ["admin", "editor"]
      },
      {
        id: "global-master",
        title: "Global Master",
        icon: <Icon.Circle size={10} />,
        type: "item",
        navLink: "/global-master",
        // navlink: "",
        // permissions: ["admin", "editor"]
      },
    ],
  },
];

export default adminMenuConfig;
